import { JafConcept } from '@way-lib-jaf/concept';
import { CGdsInstallationRow } from '@way-lib-jaf/rowLoader';

export class CGdsInstallation extends JafConcept {
  public primary = 'INS_ID';

  protected name = 'nf_gds_installation';

  protected class = 'C_Gds_Installation';

  protected rowClass = 'CGdsInstallationRow';

  protected trigramme = 'INS';

  public _flagUniqueRowset = true;

  async getInstallationByCode(codeWp: string): Promise<CGdsInstallationRow | null> {
    try {
      const response = await this.cm.gds
        .post('divers', '/installation/getInfoAccount', {
          codeWayPartner: codeWp,
        })
        .toPromise();

      if (!response) return null;

      const installation: CGdsInstallationRow = new CGdsInstallationRow(
        this,
        {
          ...response,
        },
        this.cm.getDatabase(),
      );

      return installation;
    } catch (error) {
      return null;
    }
  }

  async getInstallationById(installationId: number): Promise<CGdsInstallationRow | null> {
    try {
      const response = await this.cm.gds
        .post('divers', '/installation/getInfoAccount', {
          INS_ID: Number(installationId),
        })
        .toPromise();

      if (!response) return null;

      const installation: CGdsInstallationRow = new CGdsInstallationRow(
        this,
        {
          ...response,
        },
        this.cm.getDatabase(),
      );

      return installation;
    } catch (error) {
      return null;
    }
  }

  async getInstallationByPhone(phoneNumber: string): Promise<CGdsInstallationRow | null> {
    try {
      const response = await this.cm.gds
        .post('divers', '/installation/getInfoAccount', {
          INS_TEL_MOBILE: phoneNumber,
        })
        .toPromise();

      if (!response) return null;

      const installation: CGdsInstallationRow = new CGdsInstallationRow(
        this,
        {
          ...response,
        },
        this.cm.getDatabase(),
      );

      return installation;
    } catch (error) {
      return null;
    }
  }
}
